<template>
    <ODataLookup :data-object="dsMilestonesLkp" :whereClause="getWhereClause"
        reloadOnWhereClauseChange>
        <template #target="{ target }">
            <!-- slot fix, but this component should be rewritten -->
            <slot name="target" :target="target">
                <component v-if="is" :is="is" :ref="target" v-bind="$attrs" :value="textInputValue"></component>
                <input v-else-if="textInput" :ref="target" v-bind="$attrs" :value="textInputValue" readonly>
            </slot>
        </template>
        <template #toolbarActions>
            <div class="row">
                <div class="col">
                    <OToolbarAction :text="$t('Include Expired')" v-model="includeExpiredMS" />                      
                </div>
            </div>
        </template>
        <OColumn field="ID" width="80"></OColumn>
        <OColumn field="Name" width="200"></OColumn>
        <OColumn field="Title" width="400"></OColumn>
        <OColumn field="MilestoneDate" width="100" format="Short Date"></OColumn>
        <OColumn field="OrgUnit" width="200"></OColumn>
    </ODataLookup>
</template>

<script setup>
import { computed, ref, onMounted } from 'vue';
import { ODataLookup } from 'o365-datalookup';
import { context } from 'o365-modules';

const includeExpiredMS = ref(false);
const props = defineProps({
    textInput: Boolean,
    is: String,
    textInputValue: String,
    OrgUnitId: Number
});

const dsMilestonesLkp = $getDataObjectById("dsComplete_MilestonesLookup");

    const getWhereClause = computed( () => {
        var vFilter = [];

        if (props.OrgUnitId) {
            vFilter.push("DescendantOrgUnit_ID = " + props.OrgUnitId);
        }
        else {
            vFilter.push("DescendantOrgUnit_ID = " + context.id);
        }

        if (!includeExpiredMS.value) {    
            vFilter.push("(MilestoneDate >= GETUTCDATE() OR MilestoneDate IS NULL)");
        }

        return vFilter.join(" AND ");
    });

</script>